
import { Component, Vue } from 'vue-property-decorator';
import TextCard from '@/components/TextCard.vue';
import BaseView from '@/views/BaseView.vue';

@Component({
  components: {
    TextCard,
    BaseView
  }
})
export default class PageNotFound extends Vue {}

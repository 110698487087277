
import TextCard from '@/components/TextCard.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    TextCard,
  },
})
export default class TimeCard extends Vue {}

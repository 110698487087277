
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ImageCard extends Vue {
  @Prop({ type: String, required: true }) src?: string;
  @Prop({ type: String, required: true }) alt?: string;

  imageUrl(aaa: string | undefined) {
    return require(`@/assets/${aaa}`);
  }
}

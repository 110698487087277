import { render, staticRenderFns } from "./Doctor.vue?vue&type=template&id=060bc358&scoped=true&"
import script from "./Doctor.vue?vue&type=script&lang=ts&"
export * from "./Doctor.vue?vue&type=script&lang=ts&"
import style0 from "./Doctor.vue?vue&type=style&index=0&id=060bc358&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "060bc358",
  null
  
)

export default component.exports
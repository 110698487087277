
import { Component, Vue } from 'vue-property-decorator';
import TextCard from '@/components/TextCard.vue';

@Component({
  components: {
    TextCard
  }
})
export default class CoursesCard extends Vue {}


import Header from '@/components/Header.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router/types';

@Component({
  components: {
    Header,
  },
})
export default class App extends Vue {
  private created() {
    const to = this.$route;
    this.createPageTitle(to as Route);
  }

  @Watch('$route')
  private changeRoute(to: Route, from: Route) {
    this.createPageTitle(to);
  }

  private createPageTitle(to: Route) {
    // タイトルを設定
    if (to.meta === undefined) {
      return;
    }

    const displayBase = '医療法人 孝仁会 杉本整形外科';

    if (to.meta.title) {
      document.title = to.meta.title + ' | ' + displayBase;
    } else {
      document.title = displayBase;
    }

    // メタタグdescription設定
    const element = document.querySelector('meta[name="description"]');
    if (element === null) {
      return;
    }

    if (to.meta.desc) {
      element.setAttribute('content', to.meta.desc);
    } else {
      element.setAttribute('content', displayBase);
    }
  }
}

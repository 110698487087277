
import CoursesCard from '@/components/CoursesCard.vue';
import ImageCard from '@/components/ImageCard.vue';
import TextCard from '@/components/TextCard.vue';
import TimeCard from '@/components/TimeCard.vue';
import BaseView from '@/views/BaseView.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    TimeCard,
    TextCard,
    CoursesCard,
    BaseView,
    ImageCard,
  },
})
export default class Care extends Vue {}


import ImageCard from '@/components/ImageCard.vue';
import TextCard from '@/components/TextCard.vue';
import BaseView from '@/views/BaseView.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    TextCard,
    BaseView,
    ImageCard,
  },
})
export default class Guid extends Vue {}
